import { IBuyer, IGpRank, ITrend, IVolumeRank } from "../../../interfaces";
import { defaultAdvancedPayments } from "../objectivesIncentivesComponent/editObjIncComponents/utils";
import { Incentive } from "./incentivesTypes";
import { Objective } from "./objectiveTypes";

export type ObjIncentiveImage = {
	_id: string | null;
	title: string;
	imageUrl: string;
	notes: string;
	rejected: boolean;
	rejectedReason: string;
	buyerId: string;
	account: string;
	date: string;
};

export type ObjIncentiveImages = ObjIncentiveImage[] | [];

export interface AccountSelection {
	[accountType: string]: IBuyer[];
}

export interface Accounts {
	accountType: AccountSelection;
	premise: AccountSelection;
}

export interface ObjIncProducts {
	suppliers: string[];
	brands: string[];
	sizes: string[];
	productTypes: string[];
	packageTypes: string[];
}

export interface CustomObjIncProductFilters {
	supplier: string[];
	brand: string[];
	packageType: string[];
	size: string[];
	productType: string[];
	brandFams: string[];
}

export interface ManualProductsFilters {
	searchPhrase?: string;
	brand?: string[];
	noBrand?: string[];
	gpRank?: IGpRank[];
	packageType?: string;
	premise?: string;
	size?: string[];
	supplier?: string[];
	noSupplier?: string[];
	tags?: string[];
	trend?: ITrend;
	volumeRank?: IVolumeRank[];
	selectedProducts?: string[];
	sponsored?: string;
	excluded?: string;
	// units?: string[];
	brandFams?: string[];
}
export interface CustomObjIncAccountFilters {
	accountType: string[];
	premise: string[];
	chain: string[];
	location: string[];
}

export type MeasureSymbol = "%" | "$" | "POD" | "Display" | "CEs";
export type MeasureField = {
	label: string;
	symbol: MeasureSymbol;
};

export interface MeasureState {
	label?: string;
	startDate?: string;
	endDate?: string;
	checked?: boolean;
	symbol?: MeasureSymbol;
	trackingOption?: string;
	trackingPeriodStart?: string;
	trackingPeriodEnd?: string;
	referenceTrackingPeriod?: string;
}

export type Item = Objective | Incentive;
export type SemiItem = Omit<Item, "_id"> | Omit<Incentive, "_id">;

export const measureFields: MeasureField[] = [
	{ label: "Vol (CEs)", symbol: "CEs" },
	{ label: "Vol Δ (%)", symbol: "%" },
	{ label: "Vol Δ (CEs)", symbol: "CEs" },
	{ label: "Units(Btls/cans)", symbol: "POD" },
	{ label: "Units Δ (%)", symbol: "%" },
	{ label: "Units Δ(Btls/Cans)", symbol: "POD" },
	{ label: "GP ($)", symbol: "$" },
	{ label: "GP Δ (%)", symbol: "%" },
	{ label: "GP Δ ($)", symbol: "$" },
	{ label: "Revenue ($)", symbol: "$" },
	{ label: "Revenue Δ (%)", symbol: "%" },
	{ label: "Revenue Δ ($)", symbol: "$" },
	{ label: "PODs", symbol: "POD" },
	{ label: "PODs Simple", symbol: "POD" },
	{ label: "PODs Δ (%)", symbol: "%" },
	{ label: "PODs Δ (%) Simple", symbol: "%" },
	{ label: "PODs Δ (delta)", symbol: "POD" },
	{ label: "PODs Δ (delta) Simple", symbol: "POD" },
];

export const measureFieldsAccountSpecific: MeasureField[] = [
	{ label: "PODs  Account Specific", symbol: "POD" },
	{ label: "PODs Δ (%)  Account Specific", symbol: "%" },
	{ label: "PODs Δ (delta) Account Specific", symbol: "POD" },
	{ label: "Vol (CEs) Account Specific", symbol: "CEs" },
	{ label: "Vol Δ (%) Account Specific", symbol: "%" },
	{ label: "Vol Δ (CEs) Account Specific", symbol: "CEs" },
	{ label: "Units(Btls/cans) Account Specific", symbol: "POD" },
	{ label: "Units Δ (%) Account Specific", symbol: "%" },
	{ label: "Units Δ (Btls/Cans) Account Specific", symbol: "POD" },
	{ label: "GP ($) Account Specific", symbol: "$" },
	{ label: "GP Δ (%) Account Specific", symbol: "%" },
	{ label: "GP Δ ($) Account Specific", symbol: "$" },
];

function hasProperty<T extends object>(
	obj: T,
	prop: keyof any
): prop is keyof T {
	return prop in obj;
}
export const isIncentive = (
	item: Item | SemiItem | Incentive | Objective
): item is Incentive => {
	return (
		typeof item === "object" &&
		item !== null &&
		hasProperty(item, "supplierContribution") &&
		hasProperty(item, "rankingPrizes")
	);
};

export const initialItemState: SemiItem = {
	name: "",
	type: "image",
	description: "",
	period: "current_month",
	customPeriodStart: new Date().toISOString(),
	customPeriodEnd: new Date().toISOString(),
	payout: 0,
	users: [],
	cap: { units: "percentage", value: 100 },
	threshold: { units: "percentage", value: 0 },
	favorite: false,
	archived: false,
	products: {} as ObjIncProducts,
	expanded: false,
	status: 1,
	isOpenEnded: false,
	isValid: false,
	minQuantityValue: 0,
	advancedBudget: 0,
	advancedPayments: defaultAdvancedPayments,
	customProductsSelected: true,
	customAccountsSelected: true,
	isAccountSpecific: false,
	isFullAmount: false,
	measure: undefined,
	target: 0,
	supplierContribution: 0,
	rankingPrizes: [],
};

export const initialSubItemState: SemiItem = {
	name: "",
	type: "image",
	description: "",
	period: "current_month",
	customPeriodStart: new Date().toISOString(),
	customPeriodEnd: new Date().toISOString(),
	payout: 0,
	users: [],
	cap: { units: "percentage", value: 100 },
	threshold: { units: "percentage", value: 0 },
	favorite: false,
	archived: false,
	products: {} as ObjIncProducts,
	expanded: false,
	status: 1,
	isOpenEnded: false,
	isValid: false,
	minQuantityValue: 0,
	customProductsSelected: true,
	customAccountsSelected: true,
	isAccountSpecific: false,
	isFullAmount: false,
	measure: undefined,
	target: 0,
	supplierContribution: 0,
	rankingPrizes: [],
};

export const initialSubObj = {
	parentObjectiveId: "",
	isSubObjective: true,
	...initialSubItemState,
};
export const initialSubInc = {
	parentIncentiveId: "",
	isSubIncentive: true,
	...initialSubItemState,
};
